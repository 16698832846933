var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-2"},[_c('validation-observer',{ref:"addfoodForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('h3',{staticClass:"mb-2"},[_vm._v("Add Item")]),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Item Code","label-for":"code"}},[_c('validation-provider',{attrs:{"name":" Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code","placeholder":"Item Code"},model:{value:(_vm.item.code),callback:function ($$v) {_vm.$set(_vm.item, "code", $$v)},expression:"item.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Item Name","label-for":"Name"}},[_c('validation-provider',{attrs:{"name":" Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Name","placeholder":"Item Name"},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Short Description","label-for":"ShortDescription"}},[_c('validation-provider',{attrs:{"name":" ShortDescription","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"ShortDescription","placeholder":"Short Description","rows":"3"},model:{value:(_vm.item.short_des),callback:function ($$v) {_vm.$set(_vm.item, "short_des", $$v)},expression:"item.short_des"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":" Description","label-for":"Description"}},[_c('validation-provider',{attrs:{"name":" Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"Description","placeholder":" Description","rows":"3"},model:{value:(_vm.item.breif_des),callback:function ($$v) {_vm.$set(_vm.item, "breif_des", $$v)},expression:"item.breif_des"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Category","label-for":"Category"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (category) { return category.id; },"options":_vm.category},model:{value:(_vm.selectedcategory),callback:function ($$v) {_vm.selectedcategory=$$v},expression:"selectedcategory"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Ingredient","label-for":"Ingredient"}},[_c('validation-provider',{attrs:{"name":"Ingredient","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"multiple":"","reduce":function (ingredient) { return ingredient.id; },"options":_vm.ingredient},model:{value:(_vm.selectedingredient),callback:function ($$v) {_vm.selectedingredient=$$v},expression:"selectedingredient"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Sidedish","label-for":"Sidedish"}},[_c('validation-provider',{attrs:{"name":"Sidedish","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"multiple":"","reduce":function (sidedish) { return sidedish.id; },"options":_vm.sidedish},model:{value:(_vm.selectedsidedish),callback:function ($$v) {_vm.selectedsidedish=$$v},expression:"selectedsidedish"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Image","label-for":"image"}},[_c('validation-provider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{model:{value:(_vm.item.image),callback:function ($$v) {_vm.$set(_vm.item, "image", $$v)},expression:"item.image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"CourierPrice ","label-for":"CourierPrice"}},[_c('validation-provider',{attrs:{"name":" CourierPrice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"CourierPrice","placeholder":"Courier Price "},model:{value:(_vm.item.courierprice),callback:function ($$v) {_vm.$set(_vm.item, "courierprice", $$v)},expression:"item.courierprice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"TakeawayPrice ","label-for":"TakeawayPrice"}},[_c('validation-provider',{attrs:{"name":" TakeawayPrice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"TakeawayPrice","placeholder":"Takeaway Price "},model:{value:(_vm.item.takeawayprice),callback:function ($$v) {_vm.$set(_vm.item, "takeawayprice", $$v)},expression:"item.takeawayprice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Reservation Price ","label-for":"reserveprice"}},[_c('validation-provider',{attrs:{"name":" reserveprice","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reserveprice","placeholder":"Reservation Price "},model:{value:(_vm.item.reserve_price),callback:function ($$v) {_vm.$set(_vm.item, "reserve_price", $$v)},expression:"item.reserve_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Banquet Price ","label-for":"banquetprice"}},[_c('validation-provider',{attrs:{"name":" banquetprice","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"banquetprice","placeholder":"Banquet Price "},model:{value:(_vm.item.banquet_price),callback:function ($$v) {_vm.$set(_vm.item, "banquet_price", $$v)},expression:"item.banquet_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Listing","label-for":"Choose Variantions"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"value":"1"},model:{value:(_vm.is_buffer),callback:function ($$v) {_vm.is_buffer=$$v},expression:"is_buffer"}},[_vm._v(" Buffet ")]),_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"value":"1"},model:{value:(_vm.is_reserv),callback:function ($$v) {_vm.is_reserv=$$v},expression:"is_reserv"}},[_vm._v(" Reservation ")]),_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"value":"1"},model:{value:(_vm.is_banquet),callback:function ($$v) {_vm.is_banquet=$$v},expression:"is_banquet"}},[_vm._v(" Banquet ")])],1)])],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1 mt-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }